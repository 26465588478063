import React from 'react'
import PropTypes from 'prop-types'
import { Link, Button } from 'gatsby'
import Layout from '../components/Layout'
import BlogRoll from '../components/BlogRoll'

export default class IndexPage extends React.Component {
  handleActivateNewsletter = (e) => {
    e.preventDefault();
    document.querySelector(".modal").classList.add("is-active");
    document.querySelector(".js-newsletter-email-input").focus();
  }

  render(){
    return (
      <Layout>
        <section className="section section--gradient">
          <div className="container">
            <div className="section">
              <div className="columns">
                <div className="content column is-10 is-offset-1">
                  <div className="columns">
                    <div className="column is-one-quarter">
                      <p className="header-accent margin-bot-0">Discover</p>
                      <h1 className="has-text-weight-bold margin-top-0 is-size-3-mobile is-size-2-tablet is-size-1-widescreen">
                        Flour & Tacos
                      </h1>
                      <p>
                      I’ve always missed great Mexican food everywhere I’ve lived. So I’m on a mission to experience the best tacos the world has to offer. Let’s learn from the chefs, restaurants, farmers, and specialized suppliers that make it all possible. 
                      </p>
                      <p>
                      Every week I’ll experiment with different Mexican dishes to try to understand how to recreate the flavor I’ve always loved and to pursue the humble art of the taco.
                      </p>
                      <Link className="btn" onClick={this.handleActivateNewsletter}>
                        Get Recipes & Tips By Email
                      </Link>
                    </div>
                    <div className="column is-offset-1">
                      <div className="hero-image-container">
                        <img src="/img/tacos-tortillas-hero.png" alt="Tacos and Tortillas" />
                        <img src="/img/hero-pig.svg" className="hero-pig" alt="pig icon" />
                      </div>
                    </div>
                  </div>
                  <div className="content">
                    <div className="columns">
                      <div className="column is-5 is-offset-1">
                        <img src="/img/happy-taco.svg" className="happy-taco" alt="happy taco icon" />
                      </div>
                      <div className="column is-3 is-offset-3">
                        <img src="/img/happy-pepper.svg" className="happy-pepper" alt="happy pepper icon" />
                      </div>
                    </div>
                  </div>
                  <div className="column is-12">
                    <BlogRoll />
                    <div className="column is-12 has-text-centered">
                      <Link className="btn" to="/blog">
                        Read more
                      </Link>
                    </div>
                  </div>
                </div>
                </div>
              </div>
            </div>
        </section>
      </Layout>
    )
  }
}

IndexPage.propTypes = {
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  title: PropTypes.string,
  heading: PropTypes.string,
  subheading: PropTypes.string,
  mainpitch: PropTypes.object,
  description: PropTypes.string,
  intro: PropTypes.shape({
    blurbs: PropTypes.array,
  }),
}